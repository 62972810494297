<template>
  <div class="app-container qrCodeRecord-page">
    <div class="filter-line">
      <span>
        <el-button @click="getTableList" icon="el-icon-refresh-right">{{
          this.$t("commons.refresh")
        }}</el-button>
        <el-button type="primary" @click="exportHandler">{{
          this.$t("commons.export")
        }}</el-button>
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        <i class="iconfont icon-loudoutu"></i>
        筛选
      </div>
    </div>
    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
  </div>
</template>
<script>
import finalTable from "@/components/FinalTable";
import {
  getQRList,
  qrListExport,
} from "@/api/ruge/bms/customerService/qrCodeRecord";
import { dateFormat } from "@/filters/index";
export default {
  name: "qrCodeRecord",
  components: { finalTable },
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          { prop: "createTime", label: "生成时间", width: "" },
          { prop: "phone", label: "用户手机号", width: "" },
          { prop: "nickName", label: "微信昵称", width: "" },
          { prop: "operation", label: "操作", width: "115" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          phone: {
            type: "input",
            label: "用户手机号",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入用户手机号",
            prefixIcon: "el-icon-search",
          },
          nickName: {
            type: "input",
            label: "微信昵称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入微信昵称",
            prefixIcon: "el-icon-search",
          },

          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          createTime: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          codeTime: {
            type: "dateRange",
            pickerType: "date",
            label: "二维码时间",
            value: [],
          },
          phone: {
            inline: true,
            value: "",
          },
          nickName: {
            inline: true,
            value: "",
          },
          // openInvoiceType: {
          //   inline: true,
          //   value: "",
          // },
          // invoiceNo: {
          //   inline: true,
          //   value: "",
          // },
          // buyerMobilePhone: {
          //   inline: true,
          //   value: "",
          // },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      loadingFlag: false,
      requestParams: {
        nickName: "",
        current: 1,
        rowCount: 10,
        startTime: "",
        endTime: "",
        phone: "",
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },

  created() {
    this.getTableList();
  },
  methods: {
    dealTime(datas) {
      console.log("datas", datas);
      if (datas.params.codeTime && datas.params.codeTime.length > 0) {
        this.requestParams.startTime = datas.params.codeTime[0]
          ? dateFormat(datas.params.codeTime[0], "YYYY-MM-DD HH:mm:ss")
          : null;
        this.requestParams.endTime = datas.params.codeTime[1]
          ? dateFormat(
              new Date(datas.params.codeTime[1]).getTime() +
                1000 * 60 * 60 * 24 -
                1,
              "YYYY-MM-DD HH:mm:ss"
            )
          : null;
        delete datas.params.codeTime;
      }
      // else {
      //   this.requestParams.startTime = null;
      //   this.requestParams.endTime = null;
      // }
      return datas;
    },
    tableEventHandler(datas) {
      console.log("datas", datas);
      if (datas.type === "goSearch") {
        this.requestParams.current = 1;
        this.dataset.pageVO.current = 1;
        this.requestParams.phone = datas.params.phone;
        this.requestParams.nickName = datas.params.nickName;
        this.dealTime(datas);
        this.requestParams = { ...this.requestParams };
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        this.dataset.pageVO.current = datas.params.current.page;
        this.requestParams.current = datas.params.current.page;
        this.requestParams.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            // datas.row
            this.openDetailDialog(datas.row);
            break;
        }
      }
    },
    getTableList() {
      this.loadingFlag = true;
      getQRList(this.requestParams)
        .then((res) => {
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
          console.log("res", res);
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    exportHandler() {
      qrListExport(this.requestParams).then((taskId) => {
        this.$message({
          type: "success",
          message: this.$t("message.operationSuccess"),
        });
        let exportObj = {
          taskId,
          taskName: "二维码记录列表",
          taskStatus: 0,
          rootPath: "customerWXPath",
        };
        this.$store.dispatch("PushExportNotice", exportObj);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.qrCodeRecord-page {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }
    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
  }
  ::v-deep .el-dialog__body {
    padding-top: 0 !important;
    padding-bottom: 20px !important;
  }
}
</style>