import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 二维码记录 - 分页查询
 * @param params
 */
export function getQRList(params) {
  console.log(envInfo.bgApp.customerWXPath)
  return request({
    url: envInfo.bgApp.customerWXPath + '/customer/queryDoorOpenLog',
    method: 'get',
    params,
  });
}

/**
 * 二维码记录 - 导出
 * @param params
 */
export function qrListExport(params) {
  return request({
    url: envInfo.bgApp.customerWXPath + '/customer/exportDoorOpen',
    method: 'get',
    params,
  });
}
